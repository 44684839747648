/* Template Name: Nody - Responsive Bootstrap 4 Landing Page Template
   Author: Themesdesign
   Version: 1.0.0
   Created: Jan 2020
   File Description: Main Css file of the template
*/
/**********************
01.General
02.Menu
03.Components
04.Helper
05.Loader
06.Hero
07.About Us
08.services
09.features
10.project
11.testimonial
12.blog
13.contact
14.footer
***********************/
@import url("https://fonts.googleapis.com/css?family=Muli:400,500,600,700|Rubik:400,500,700&display=swap");
a {
  text-decoration: none !important;
  outline: none;
  color: #3956de;
}

body {
  font-family: "Muli", sans-serif;
  color: #343a40;
  background: #ffffff;
}

p {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
}

.nav-item .nav-link {
  display: inline-block;
  color: #343a40;
  text-decoration: none;
}

.navbar .menu-social-icon li {
  margin: 0 8px;
}

.navbar .menu-social-icon li .menu-social-link {
  color: #9b9bae;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.navbar .menu-social-icon li .menu-social-link:hover {
  color: #3956de;
}

.navbar-custom {
  padding: 14px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
}

.navbar-custom .logo .logo-dark {
  display: none;
}

.navbar-custom .logo .ligo-light {
  display: inline-block;
}

.navbar-custom .navbar-nav li a {
  line-height: 26px;
  color: #9b9bae;
  font-size: 15px;
  font-weight: 400;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: transparent !important;
  padding: 6px 0;
  margin: 0 18px;
}

.navbar-custom .navbar-nav li .nav-link {
  padding-right: 0px;
  padding-left: 0px;
}

.navbar-custom .navbar-nav li.active a,
.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:active {
  color: #3956de !important;
}

.navbar-custom .navbar-nav.menu-social-icon .nav-item .nav-link {
  margin: 0 10px !important;
}

.navbar-custom .navbar-toggles {
  padding: 0.25rem 0.75rem;
  font-size: 18px;
  background: 0 0;
  border: 1px solid transparent;
  color: #ffffff;
  outline: 0;
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #ffffff;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: #3956de;
}

.menu-toggle {
  padding: 4.5px 10px !important;
}

.menu-toggle span {
  line-height: 27px;
}

.navbar-light .navbar-nav li a {
  color: rgba(255, 255, 255, 0.6) !important;
}

.navbar-light .navbar-nav li.active a,
.navbar-light .navbar-nav li a:hover,
.navbar-light .navbar-nav li a:active {
  color: #ffffff !important;
}

.navbar-light .menu-social-icon li {
  margin: 0 8px;
}

.navbar-light .menu-social-icon li .menu-social-link {
  color: rgba(255, 255, 255, 0.6) !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.navbar-light .menu-social-icon li .menu-social-link:hover {
  color: #ffffff !important;
}

.navbar-light .logo .logo-dark {
  display: none !important;
}

.navbar-light .logo .logo-light {
  display: inline-block !important;
}

/*---NAVBAR STICKY--*/
.nav-sticky.navbar-custom {
  margin-top: 0px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  color: #000000 !important;
}

.nav-sticky.navbar-custom .navbar-toggles {
  padding: 0.25rem 0.75rem;
  border: 1px solid transparent;
  outline: 0;
}

.nav-sticky.navbar-custom .navbar-nav {
  margin-top: 0px;
}

.nav-sticky.navbar-custom .navbar-nav li a {
  color: #8a8aa0 !important;
}

.nav-sticky.navbar-custom .navbar-nav li.active a,
.nav-sticky.navbar-custom .navbar-nav li a:hover,
.nav-sticky.navbar-custom .navbar-nav li a:active {
  color: #3956de !important;
}

.nav-sticky .logo .logo-dark {
  display: inline-block !important;
}

.nav-sticky .logo .logo-light {
  display: none !important;
}

.navbar-toggler:focus {
  outline: none;
}

/*  */
@media (min-width: 200px) and (max-width: 768px) {
  .navbar-custom {
    margin-top: 0px;
    background-color: #ffffff !important;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    color: #ffffff !important;
  }
  .navbar-custom .navbar-nav {
    margin-top: 10px;
  }
  .navbar-custom .navbar-nav li a {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    color: #9b9bae !important;
    margin: 0px;
  }
  .navbar-custom .navbar-nav li.active a {
    border-color: transparent;
  }
  .navbar-custom .navbar-nav li.active a,
  .navbar-custom .navbar-nav li a:hover,
  .navbar-custom .navbar-nav li a:active {
    color: #3956de !important;
  }
  .navbar-custom > .container {
    width: 90%;
  }
  .navbar-custom .logo .logo-dark {
    display: inline-block !important;
  }
  .navbar-custom .logo .logo-light {
    display: none !important;
  }
  .navbar-custom.navbar-light .navbar-nav li.active a,
  .navbar-custom.navbar-light .navbar-nav li a:hover,
  .navbar-custom.navbar-light .navbar-nav li a:active {
    color: #343a40 !important;
  }
  .navbar .menu-social-icon li {
    margin: 0 8px;
  }
  .navbar .menu-social-icon li .menu-social-link {
    color: #9b9bae !important;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .navbar .menu-social-icon li .menu-social-link:hover {
    color: #3956de !important;
  }
  .nav-sticky.navbar-custom .navbar-nav {
    margin-top: 10px;
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #343a40;
  }
  .navbar-collapse {
    padding: 0 20px;
  }
  .navbar-light .nav-item .nav-link:after {
    background: #343a40;
  }
}

.btn {
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 15px !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn.btn-lg {
  padding: 10px 22px !important;
  font-size: 18px !important;
}

.btn.btn-sm {
  padding: 8px 16px !important;
  font-size: 14px !important;
}

.btn.btn-primary {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3956de),
    to(#657be5)
  );
  background: linear-gradient(to right, #3956de 0%, #657be5 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(57, 86, 222, 0.15);
  box-shadow: 0 4px 20px rgba(57, 86, 222, 0.15);
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary.focus,
.btn.btn-primary:active,
.btn.btn-primary.active {
  -webkit-box-shadow: 0 3px 10px rgba(57, 86, 222, 0.5);
  box-shadow: 0 3px 10px rgba(57, 86, 222, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-primary {
  border: 1px solid #3956de !important;
  color: #3956de !important;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:active,
.btn.btn-outline-primary.active {
  background-color: #3956de !important;
  border: 1px solid #3956de !important;
  -webkit-box-shadow: 0 3px 10px rgba(57, 86, 222, 0.5);
  box-shadow: 0 3px 10px rgba(57, 86, 222, 0.5);
  color: #ffffff !important;
}

.btn.btn-secondary {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#6c757d),
    to(#868e96)
  );
  background: linear-gradient(to right, #6c757d 0%, #868e96 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(108, 117, 125, 0.15);
  box-shadow: 0 4px 20px rgba(108, 117, 125, 0.15);
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus,
.btn.btn-secondary.focus,
.btn.btn-secondary:active,
.btn.btn-secondary.active {
  -webkit-box-shadow: 0 3px 10px rgba(108, 117, 125, 0.5);
  box-shadow: 0 3px 10px rgba(108, 117, 125, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-secondary {
  border: 1px solid #6c757d !important;
  color: #6c757d !important;
}

.btn.btn-outline-secondary:hover,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary.active {
  background-color: #6c757d !important;
  border: 1px solid #6c757d !important;
  -webkit-box-shadow: 0 3px 10px rgba(108, 117, 125, 0.5);
  box-shadow: 0 3px 10px rgba(108, 117, 125, 0.5);
  color: #ffffff !important;
}

.btn.btn-success {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2dd6b7),
    to(#58dec6)
  );
  background: linear-gradient(to right, #2dd6b7 0%, #58dec6 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(45, 214, 183, 0.15);
  box-shadow: 0 4px 20px rgba(45, 214, 183, 0.15);
}

.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success.focus,
.btn.btn-success:active,
.btn.btn-success.active {
  -webkit-box-shadow: 0 3px 10px rgba(45, 214, 183, 0.5);
  box-shadow: 0 3px 10px rgba(45, 214, 183, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-success {
  border: 1px solid #2dd6b7 !important;
  color: #2dd6b7 !important;
}

.btn.btn-outline-success:hover,
.btn.btn-outline-success:focus,
.btn.btn-outline-success.focus,
.btn.btn-outline-success:active,
.btn.btn-outline-success.active {
  background-color: #2dd6b7 !important;
  border: 1px solid #2dd6b7 !important;
  -webkit-box-shadow: 0 3px 10px rgba(45, 214, 183, 0.5);
  box-shadow: 0 3px 10px rgba(45, 214, 183, 0.5);
  color: #ffffff !important;
}

.btn.btn-info {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#1097bd),
    to(#15bceb)
  );
  background: linear-gradient(to right, #1097bd 0%, #15bceb 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(16, 151, 189, 0.15);
  box-shadow: 0 4px 20px rgba(16, 151, 189, 0.15);
}

.btn.btn-info:hover,
.btn.btn-info:focus,
.btn.btn-info.focus,
.btn.btn-info:active,
.btn.btn-info.active {
  -webkit-box-shadow: 0 3px 10px rgba(16, 151, 189, 0.5);
  box-shadow: 0 3px 10px rgba(16, 151, 189, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-info {
  border: 1px solid #1097bd !important;
  color: #1097bd !important;
}

.btn.btn-outline-info:hover,
.btn.btn-outline-info:focus,
.btn.btn-outline-info.focus,
.btn.btn-outline-info:active,
.btn.btn-outline-info.active {
  background-color: #1097bd !important;
  border: 1px solid #1097bd !important;
  -webkit-box-shadow: 0 3px 10px rgba(16, 151, 189, 0.5);
  box-shadow: 0 3px 10px rgba(16, 151, 189, 0.5);
  color: #ffffff !important;
}

.btn.btn-warning {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffbb13),
    to(#ffca46)
  );
  background: linear-gradient(to right, #ffbb13 0%, #ffca46 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(255, 187, 19, 0.15);
  box-shadow: 0 4px 20px rgba(255, 187, 19, 0.15);
}

.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-warning.focus,
.btn.btn-warning:active,
.btn.btn-warning.active {
  -webkit-box-shadow: 0 3px 10px rgba(255, 187, 19, 0.5);
  box-shadow: 0 3px 10px rgba(255, 187, 19, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-warning {
  border: 1px solid #ffbb13 !important;
  color: #ffbb13 !important;
}

.btn.btn-outline-warning:hover,
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:active,
.btn.btn-outline-warning.active {
  background-color: #ffbb13 !important;
  border: 1px solid #ffbb13 !important;
  -webkit-box-shadow: 0 3px 10px rgba(255, 187, 19, 0.5);
  box-shadow: 0 3px 10px rgba(255, 187, 19, 0.5);
  color: #ffffff !important;
}

.btn.btn-danger {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f43958),
    to(#f76981)
  );
  background: linear-gradient(to right, #f43958 0%, #f76981 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(244, 57, 88, 0.15);
  box-shadow: 0 4px 20px rgba(244, 57, 88, 0.15);
}

.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger.focus,
.btn.btn-danger:active,
.btn.btn-danger.active {
  -webkit-box-shadow: 0 3px 10px rgba(244, 57, 88, 0.5);
  box-shadow: 0 3px 10px rgba(244, 57, 88, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-danger {
  border: 1px solid #f43958 !important;
  color: #f43958 !important;
}

.btn.btn-outline-danger:hover,
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger.focus,
.btn.btn-outline-danger:active,
.btn.btn-outline-danger.active {
  background-color: #f43958 !important;
  border: 1px solid #f43958 !important;
  -webkit-box-shadow: 0 3px 10px rgba(244, 57, 88, 0.5);
  box-shadow: 0 3px 10px rgba(244, 57, 88, 0.5);
  color: #ffffff !important;
}

.btn.btn-dark {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#343a40),
    to(#4b545c)
  );
  background: linear-gradient(to right, #343a40 0%, #4b545c 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(52, 58, 64, 0.15);
  box-shadow: 0 4px 20px rgba(52, 58, 64, 0.15);
}

.btn.btn-dark:hover,
.btn.btn-dark:focus,
.btn.btn-dark.focus,
.btn.btn-dark:active,
.btn.btn-dark.active {
  -webkit-box-shadow: 0 3px 10px rgba(52, 58, 64, 0.5);
  box-shadow: 0 3px 10px rgba(52, 58, 64, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-dark {
  border: 1px solid #343a40 !important;
  color: #343a40 !important;
}

.btn.btn-outline-dark:hover,
.btn.btn-outline-dark:focus,
.btn.btn-outline-dark.focus,
.btn.btn-outline-dark:active,
.btn.btn-outline-dark.active {
  background-color: #343a40 !important;
  border: 1px solid #343a40 !important;
  -webkit-box-shadow: 0 3px 10px rgba(52, 58, 64, 0.5);
  box-shadow: 0 3px 10px rgba(52, 58, 64, 0.5);
  color: #ffffff !important;
}

.btn.btn-muted {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#9b9bae),
    to(#b7b7c5)
  );
  background: linear-gradient(to right, #9b9bae 0%, #b7b7c5 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(155, 155, 174, 0.15);
  box-shadow: 0 4px 20px rgba(155, 155, 174, 0.15);
}

.btn.btn-muted:hover,
.btn.btn-muted:focus,
.btn.btn-muted.focus,
.btn.btn-muted:active,
.btn.btn-muted.active {
  -webkit-box-shadow: 0 3px 10px rgba(155, 155, 174, 0.5);
  box-shadow: 0 3px 10px rgba(155, 155, 174, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-muted {
  border: 1px solid #9b9bae !important;
  color: #9b9bae !important;
}

.btn.btn-outline-muted:hover,
.btn.btn-outline-muted:focus,
.btn.btn-outline-muted.focus,
.btn.btn-outline-muted:active,
.btn.btn-outline-muted.active {
  background-color: #9b9bae !important;
  border: 1px solid #9b9bae !important;
  -webkit-box-shadow: 0 3px 10px rgba(155, 155, 174, 0.5);
  box-shadow: 0 3px 10px rgba(155, 155, 174, 0.5);
  color: #ffffff !important;
}

.btn.btn-light {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fbfbfd),
    to(white)
  );
  background: linear-gradient(to right, #fbfbfd 0%, white 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(251, 251, 253, 0.15);
  box-shadow: 0 4px 20px rgba(251, 251, 253, 0.15);
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light.focus,
.btn.btn-light:active,
.btn.btn-light.active {
  -webkit-box-shadow: 0 3px 10px rgba(251, 251, 253, 0.5);
  box-shadow: 0 3px 10px rgba(251, 251, 253, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-light {
  border: 1px solid #fbfbfd !important;
  color: #fbfbfd !important;
}

.btn.btn-outline-light:hover,
.btn.btn-outline-light:focus,
.btn.btn-outline-light.focus,
.btn.btn-outline-light:active,
.btn.btn-outline-light.active {
  background-color: #fbfbfd !important;
  border: 1px solid #fbfbfd !important;
  -webkit-box-shadow: 0 3px 10px rgba(251, 251, 253, 0.5);
  box-shadow: 0 3px 10px rgba(251, 251, 253, 0.5);
  color: #ffffff !important;
}

.btn.btn-black {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#000000),
    to(#1a1a1a)
  );
  background: linear-gradient(to right, #000000 0%, #1a1a1a 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.btn.btn-black:hover,
.btn.btn-black:focus,
.btn.btn-black.focus,
.btn.btn-black:active,
.btn.btn-black.active {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-black {
  border: 1px solid #000000 !important;
  color: #000000 !important;
}

.btn.btn-outline-black:hover,
.btn.btn-outline-black:focus,
.btn.btn-outline-black.focus,
.btn.btn-outline-black:active,
.btn.btn-outline-black.active {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  color: #ffffff !important;
}

.btn.btn-white {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffffff),
    to(white)
  );
  background: linear-gradient(to right, #ffffff 0%, white 100%);
  border: none;
  color: #ffffff;
  -webkit-box-shadow: 0 4px 20px rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.15);
}

.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white.focus,
.btn.btn-white:active,
.btn.btn-white.active {
  -webkit-box-shadow: 0 3px 10px rgba(255, 255, 255, 0.5);
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.5);
  color: #ffffff !important;
}

.btn.btn-outline-white {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}

.btn.btn-outline-white:hover,
.btn.btn-outline-white:focus,
.btn.btn-outline-white.focus,
.btn.btn-outline-white:active,
.btn.btn-outline-white.active {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  -webkit-box-shadow: 0 3px 10px rgba(255, 255, 255, 0.5);
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.5);
  color: #ffffff !important;
}

.btn.btn-light {
  background-color: #fbfbfd;
  border: 1px solid #fbfbfd;
  color: #343a40 !important;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light.focus,
.btn.btn-light:active,
.btn.btn-light.active {
  background-color: white;
  border: 1px solid white;
  -webkit-box-shadow: 0 0 0 2px rgba(251, 251, 253, 0.08);
  box-shadow: 0 0 0 2px rgba(251, 251, 253, 0.08);
  color: #343a40 !important;
}

.btn.btn-outline-light {
  border: 1px solid #fbfbfd;
  color: #fbfbfd !important;
}

.btn.btn-outline-light:hover,
.btn.btn-outline-light:focus,
.btn.btn-outline-light.focus,
.btn.btn-outline-light:active,
.btn.btn-outline-light.active {
  background-color: #fbfbfd;
  border: 1px solid #fbfbfd;
  color: #343a40 !important;
}

.badge {
  padding: 6px 16px;
  font-weight: 400;
}

.badge.badge-primary {
  background: rgba(57, 86, 222, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(57, 86, 222, 0.5);
  box-shadow: 0 1px 2px rgba(57, 86, 222, 0.5);
  color: #3956de;
}

.text-primary {
  color: #3956de !important;
}

.bg-primary {
  background-color: #3956de !important;
}

.icon-fill-primary {
  fill: rgba(57, 86, 222, 0.15);
}

.bg-soft-primary {
  background: rgba(57, 86, 222, 0.07);
}

.text-secondary {
  color: #6c757d !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.icon-fill-secondary {
  fill: rgba(108, 117, 125, 0.15);
}

.bg-soft-secondary {
  background: rgba(108, 117, 125, 0.07);
}

.text-success {
  color: #2dd6b7 !important;
}

.bg-success {
  background-color: #2dd6b7 !important;
}

.icon-fill-success {
  fill: rgba(45, 214, 183, 0.15);
}

.bg-soft-success {
  background: rgba(45, 214, 183, 0.07);
}

.text-info {
  color: #1097bd !important;
}

.bg-info {
  background-color: #1097bd !important;
}

.icon-fill-info {
  fill: rgba(16, 151, 189, 0.15);
}

.bg-soft-info {
  background: rgba(16, 151, 189, 0.07);
}

.text-warning {
  color: #ffbb13 !important;
}

.bg-warning {
  background-color: #ffbb13 !important;
}

.icon-fill-warning {
  fill: rgba(255, 187, 19, 0.15);
}

.bg-soft-warning {
  background: rgba(255, 187, 19, 0.07);
}

.text-danger {
  color: #f43958 !important;
}

.bg-danger {
  background-color: #f43958 !important;
}

.icon-fill-danger {
  fill: rgba(244, 57, 88, 0.15);
}

.bg-soft-danger {
  background: rgba(244, 57, 88, 0.07);
}

.text-dark {
  color: #343a40 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

.icon-fill-dark {
  fill: rgba(52, 58, 64, 0.15);
}

.bg-soft-dark {
  background: rgba(52, 58, 64, 0.07);
}

.text-muted {
  color: #9b9bae !important;
}

.bg-muted {
  background-color: #9b9bae !important;
}

.icon-fill-muted {
  fill: rgba(155, 155, 174, 0.15);
}

.bg-soft-muted {
  background: rgba(155, 155, 174, 0.07);
}

.text-light {
  color: #fbfbfd !important;
}

.bg-light {
  background-color: #fbfbfd !important;
}

.icon-fill-light {
  fill: rgba(251, 251, 253, 0.15);
}

.bg-soft-light {
  background: rgba(251, 251, 253, 0.07);
}

.text-black {
  color: #000000 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.icon-fill-black {
  fill: rgba(0, 0, 0, 0.15);
}

.bg-soft-black {
  background: rgba(0, 0, 0, 0.07);
}

.text-white {
  color: #ffffff !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.icon-fill-white {
  fill: rgba(255, 255, 255, 0.15);
}

.bg-soft-white {
  background: rgba(255, 255, 255, 0.07);
}

.shadow {
  -webkit-box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.08) !important;
  box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.08) !important;
}

.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.bg-overlay {
  background-color: #000000;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.line-height-1_6 {
  line-height: 1.6;
}

.line-height-1_4 {
  line-height: 1.4;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-17 {
  font-size: 17px;
}

.f-18 {
  font-size: 18px;
}

.f-19 {
  font-size: 19px;
}

.f-20 {
  font-size: 20px;
}

.f-21 {
  font-size: 21px;
}

.f-22 {
  font-size: 22px;
}

.f-23 {
  font-size: 23px;
}

.f-24 {
  font-size: 24px;
}

.w-60 {
  width: 60%;
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 2.25rem;
  width: 2.25rem;
}

.avatar {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar-lg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-xl {
  height: 6rem;
  width: 6rem;
}

.avatar-xxl {
  height: 7.5rem;
  width: 7.5rem;
}

.icon-size-15 {
  height: 15px;
  width: 15px;
}

.icon-xs {
  height: 20px;
  width: 20px;
}

.icon-lg {
  height: 30px;
  width: 30px;
}

.icon-xl {
  height: 35px;
  width: 35px;
}

.sw_1-5 {
  stroke-width: 1.5px;
}

.icon {
  position: relative;
  top: -2px;
}

.right-icon {
  line-height: 0;
  font-size: 24px;
  position: relative;
  top: 2px;
}

.hero-bottom-shape {
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
}

.hero-bottom-shape img {
  width: 100%;
  height: auto;
}

.card {
  margin-bottom: 30px;
  border-color: #eaeaf5;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(
    linear,
    right bottom,
    left top,
    from(#1097bd),
    to(#3956de)
  );
  background: linear-gradient(to top left, #1097bd 0%, #3956de 100%);
  z-index: 9999999;
}

#preloader #status {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.hero-1-bg {
  padding: 200px 0 180px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.hero-1-bg .hero-1-title {
  font-size: 46px;
}

.hero-2-bg {
  padding: 250px 0 340px;
}

.hero-2-bg .hero-2-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.hero-2-bg .hero-2-title {
  font-size: 48px;
  text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.15);
}

.hero-3-bg {
  padding: 210px 0 380px;
}

.hero-3-bg .hero-3-title {
  font-size: 44px;
  text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.15);
}

.hero-4-bg {
  padding: 220px 0 180px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-4-bg .hero-4-title {
  font-size: 46px;
}

.hero-5-bg {
  padding: 100px 0 80px;
  overflow: hidden;
}

.hero-5-bg .hero-5-title {
  font-size: 44px;
  text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.15);
}

.hero-5-bg .Subcribe-form form {
  position: relative;
  margin: 0px auto;
}

.hero-5-bg .Subcribe-form input {
  padding: 12px 20px;
  min-width: 290px;
  height: 46px;
  font-size: 17px;
  color: #9b9bae !important;
  outline: none !important;
  position: relative;
}

@media (min-width: 200px) and (max-width: 768px) {
  .hero-1-bg {
    padding: 120px 0 60px;
  }
  .hero-2-bg {
    padding: 150px 0;
  }
  .hero-3-bg {
    padding: 160px 0 180px;
  }
  .hero-4-bg {
    padding: 110px 0 80px;
  }
  .hero-5-bg .hero-5-img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}

@media (min-width: 200px) and (max-width: 576px) {
  .hero-1-title,
  .hero-2-title {
    font-size: 36px !important;
  }
  .hero-3-bg {
    padding: 120px 0 120px;
  }
  .hero-3-bg .hero-3-title {
    font-size: 26px;
  }
  .hero-4-title,
  .hero-5-title {
    font-size: 32px !important;
  }
}

.about-img {
  padding: 40px;
}

.service-box {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box .service-icon-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.service-box .service-icon-bg .service-box-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-filter: brightness(0.55);
  filter: brightness(0.55);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box .service-title,
.service-box .read-more {
  color: #343a40;
}

.service-box .service-subtitle {
  color: #9b9bae;
}

.service-box:hover {
  background: #3956de;
  border-color: #3956de;
}

.service-box:hover .service-box-icon {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}

.service-box:hover .service-title,
.service-box:hover .read-more,
.service-box:hover .service-subtitle,
.service-box:hover .service-box-icon {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box:hover .service-title,
.service-box:hover .read-more {
  color: #ffffff;
}

.service-box:hover .service-subtitle {
  color: rgba(255, 255, 255, 0.5);
}

.features-content .nav .nav-link {
  padding: 30px;
}

.features-content .nav .nav-link.active {
  background: #ffffff !important;
  -webkit-box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.08);
}

.project-box {
  position: relative;
}

.project-box .project-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.project-box .project-overlay .project-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  left: 0;
  text-align: center;
}

.project-box .project-overlay .project-content .project-icon {
  border: 1px solid #ffffff;
  color: #ffffff;
  height: 48px;
  width: 48px;
  line-height: 48px;
  margin: 0 auto;
  border-radius: 50px;
}

.project-box:hover .project-overlay {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
}

.testi-content .testi-box .test-user-info .testi-user-img {
  border: 5px solid #ffffff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.testi-content .owl-nav {
  display: none;
}

.testi-content .owl-dots {
  margin-top: 16px;
}

.testi-content .owl-dots .owl-dot {
  outline: none;
}

.testi-content .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  border: 3px solid #ffffff;
  margin: 5px 4px;
  background: rgba(57, 86, 222, 0.4);
}

.testi-content .owl-dots .owl-dot.active span,
.testi-content .owl-dots .owl-dot:hover span {
  background: #3956de;
  -webkit-box-shadow: 0 0 0 1px #3956de;
  box-shadow: 0 0 0 1px #3956de;
}

.testi-content .owl-item.center .testi-box .testi-user-img,
.testi-content .owl-item:hover .testi-box .testi-user-img {
  -webkit-box-shadow: 0 3px 0 -1px rgba(57, 86, 222, 0.6);
  box-shadow: 0 3px 0 -1px rgba(57, 86, 222, 0.6);
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.blog-box .blog-img {
  max-width: 150px;
}

.blog-box .blog-box-content {
  margin-bottom: 30px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  /* background-color: #fbfbfd; */
  /* border-color: #3956de; */
}

.form-control {
  padding: 8px 10px;
  font-size: 14px;
  height: 40px;
  background-color: rgba(52, 58, 64, 0.01);
  border: 1px solid rgba(155, 155, 174, 0.3);
}

.form-control::-webkit-input-placeholder {
  color: #acacbc;
  font-size: 14px;
}

.form-control:-ms-input-placeholder {
  color: #acacbc;
  font-size: 14px;
}

.form-control::-ms-input-placeholder {
  color: #acacbc;
  font-size: 14px;
}

.form-control::placeholder {
  color: #acacbc;
  font-size: 14px;
}

.error {
  margin: 8px 0px;
  display: none;
  color: #f43958;
}

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}

.error_message {
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  border: 2px solid #f43958;
  color: #f43958;
  border-radius: 5px;
  font-size: 14px;
}

.contact-loader {
  display: none;
}

.footer {
  padding: 70px 0 25px;
  background: #292d32;
  background-position: center center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
}

.footer .footer-sub-menu li {
  padding: 6px 0;
}

.footer .footer-sub-menu li .footer-link {
  color: rgba(255, 255, 255, 0.6);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.footer .footer-sub-menu li .footer-link:hover {
  color: #ffffff;
  margin-left: 5px;
}
/*# sourceMappingURL=style.css.map */
